import React, { AnimationEvent, ReactNode, useCallback, useEffect, useState } from 'react';

import styled, { FlattenSimpleInterpolation, css, keyframes } from 'styled-components';



import { IconName } from '@Icon';

import { Button } from '@/components/shared/Button';
import { Icon } from '@/components/shared/Icon';
import {
  BorderRadius,
  ColorPalette,
  ColorTokens,
  StyleShadow,
  TransitionEasings,
  TransitionSpeeds,
  TypographyTokens,
  space,
} from '@/styles';

import { Snackbar, useSnackbarContext } from '..';


type Props = {
  id: number;
  children: ReactNode;
  theme: Snackbar['theme'];
  buttonText: string | undefined;
  onClickButton: (() => void) | undefined;
  autoHide: boolean;
};

export const Item = ({ id, children, theme, buttonText, onClickButton, autoHide = true }: Props): JSX.Element => {
  const { removeSnackbar } = useSnackbarContext();

  const [isRemoving, setIsRemoving] = useState(false);

  const handleAnimationEnd = (e: AnimationEvent<HTMLDivElement>) =>
    window.getComputedStyle(e.currentTarget).opacity === '0' && removeSnackbar(id);

  const handleClose = () => {
    setIsRemoving(true);
  };

  useEffect(() => {
    const timer = window.setTimeout(() => {
      if (autoHide) {
        setIsRemoving(true);
      }
    }, hideTransitionSpeedsTime);

    return () => window.clearTimeout(timer);
  }, [id, autoHide, removeSnackbar]);

  const showButton = buttonText != null && buttonText !== '' && onClickButton != null;

  const handleOnClickButton = useCallback(() => {
    if (onClickButton != null) {
      onClickButton();
    }
    removeSnackbar(id);
  }, [onClickButton, id, removeSnackbar]);

  return (
    <StyledBase colorTheme={theme} isRemoving={isRemoving} onAnimationEnd={handleAnimationEnd}>
      <StyledContainer>
        <StyledIconAndTextContainer>
          <StyledIcon name={getIconName(theme)} colorTheme={theme} />
          <StyledText>{children}</StyledText>
        </StyledIconAndTextContainer>
        {showButton ? (
          <StyledButtonContainer>
            <Button styling="outline" onClick={handleOnClickButton}>
              {buttonText}
            </Button>
          </StyledButtonContainer>
        ) : null}
      </StyledContainer>
      <StyledCloseIcon name="close" color="Icon01" onClick={handleClose} />
    </StyledBase>
  );
};

const getIconName = (theme: Snackbar['theme']): IconName => {
  switch (theme) {
    case 'danger':
      return 'attention';
    case 'success':
      return 'success-filled';
    case 'warning':
      return 'warning';
    case 'info':
    default:
      return 'information-filled';
  }
};

const hideTransitionSpeedsTime = 5000;

const showAnimationSide = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const hideAnimationSide = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-50%, 0, 0);
  }
`;

const StyledIcon = styled(Icon)<{ colorTheme: Props['theme'] }>`
  align-self: flex-start;
  margin-right: ${space(1.5)};
  ${({ colorTheme }) => Theme[colorTheme]}
`;

const StyledCloseIcon = styled(Icon)`
  justify-content: flex-start;
  margin: auto 0;

  :hover {
    cursor: pointer;
  }
`;

const StyledIconAndTextContainer = styled.div`
  width: auto;
  margin-right: ${space(2)};
  display: flex;
  align-items: center;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0;
  button {
    min-width: 120px;
    height: 32px;
  }
`;

const StyledContainer = styled.span`
  flex-grow: 1;
  margin-right: ${space(4)};
  display: flex;
  justify-content: space-between;
`;

const StyledBase = styled.div<{ colorTheme: Props['theme']; isRemoving: boolean }>`
  position: relative;
  display: flex;
  padding: ${space(3)} ${space(2)};
  margin: 0 ${space(4)};
  ${TypographyTokens.Body1}
  pointer-events: auto;
  background: ${ColorTokens.UiBackground01};
  border-radius: ${BorderRadius.Default};
  box-shadow: ${StyleShadow.DropShadow10};
  max-width: 475px;
  animation: ${showAnimationSide} ${TransitionSpeeds.Regular} ${TransitionEasings.Enter};

  ${({ isRemoving }) =>
    isRemoving &&
    css`
      opacity: 0;
      animation: ${hideAnimationSide} ${TransitionSpeeds.Slow} ${TransitionEasings.Leave};
    `}
  & + & {
    margin-bottom: ${space(4)};
  }
`;

const Theme: Record<Snackbar['theme'], FlattenSimpleInterpolation> = {
  danger: css`
    color: ${ColorTokens.SupportDanger};
    svg {
      fill: ${ColorTokens.SupportDanger};
    }
  `,
  success: css`
    path {
      fill: ${ColorTokens.SupportSuccess};
    }
  `,
  info: css`
    svg {
      fill: ${ColorPalette.Blue50};
    }
  `,
  warning: css`
    path:first-child {
      fill: ${ColorTokens.SupportWarning};
    }
    path:nth-child(2) {
      fill: ${ColorTokens.IconOnColor};
    }
  `,
} as const;

const StyledText = styled.span`
  white-space: pre-wrap;
`;
