import styleTokens from './styleTokens.json';

export const ColorPalette = {
  White: styleTokens.Colors.White.value,
  Black: styleTokens.Colors.Black.value,

  Gray10: styleTokens.Colors.Gray.Gray10.value,
  Gray20: styleTokens.Colors.Gray.Gray20.value,
  Gray30: styleTokens.Colors.Gray.Gray30.value,
  Gray40: styleTokens.Colors.Gray.Gray40.value,
  Gray50: styleTokens.Colors.Gray.Gray50.value,
  Gray60: styleTokens.Colors.Gray.Gray60.value,
  Gray70: styleTokens.Colors.Gray.Gray70.value,
  Gray80: styleTokens.Colors.Gray.Gray80.value,
  Gray90: styleTokens.Colors.Gray.Gray90.value,
  Gray100: styleTokens.Colors.Gray.Gray100.value,

  Blue10: styleTokens.Colors.Blue.Blue10.value,
  Blue20: styleTokens.Colors.Blue.Blue20.value,
  Blue30: styleTokens.Colors.Blue.Blue30.value,
  Blue40: styleTokens.Colors.Blue.Blue40.value,
  Blue50: styleTokens.Colors.Blue.Blue50.value,
  Blue60: styleTokens.Colors.Blue.Blue60.value,
  Blue70: styleTokens.Colors.Blue.Blue70.value,
  Blue80: styleTokens.Colors.Blue.Blue80.value,
  Blue90: styleTokens.Colors.Blue.Blue90.value,
  Blue100: styleTokens.Colors.Blue.Blue100.value,

  Red10: styleTokens.Colors.Red.Red10.value,
  Red20: styleTokens.Colors.Red.Red20.value,
  Red30: styleTokens.Colors.Red.Red30.value,
  Red40: styleTokens.Colors.Red.Red40.value,
  Red50: styleTokens.Colors.Red.Red50.value,
  Red60: styleTokens.Colors.Red.Red60.value,
  Red70: styleTokens.Colors.Red.Red70.value,
  Red80: styleTokens.Colors.Red.Red80.value,
  Red90: styleTokens.Colors.Red.Red90.value,
  Red100: styleTokens.Colors.Red.Red100.value,

  Terracotta10: styleTokens.Colors.Terracotta.Terracotta10.value,
  Terracotta20: styleTokens.Colors.Terracotta.Terracotta20.value,
  Terracotta30: styleTokens.Colors.Terracotta.Terracotta30.value,
  Terracotta40: styleTokens.Colors.Terracotta.Terracotta40.value,
  Terracotta50: styleTokens.Colors.Terracotta.Terracotta50.value,
  Terracotta60: styleTokens.Colors.Terracotta.Terracotta60.value,
  Terracotta70: styleTokens.Colors.Terracotta.Terracotta70.value,
  Terracotta80: styleTokens.Colors.Terracotta.Terracotta80.value,
  Terracotta90: styleTokens.Colors.Terracotta.Terracotta90.value,
  Terracotta100: styleTokens.Colors.Terracotta.Terracotta100.value,

  Yellow10: styleTokens.Colors.Yellow.Yellow10.value,
  Yellow20: styleTokens.Colors.Yellow.Yellow20.value,
  Yellow30: styleTokens.Colors.Yellow.Yellow30.value,
  Yellow40: styleTokens.Colors.Yellow.Yellow40.value,
  Yellow50: styleTokens.Colors.Yellow.Yellow50.value,
  Yellow60: styleTokens.Colors.Yellow.Yellow60.value,
  Yellow70: styleTokens.Colors.Yellow.Yellow70.value,
  Yellow80: styleTokens.Colors.Yellow.Yellow80.value,
  Yellow90: styleTokens.Colors.Yellow.Yellow90.value,
  Yellow100: styleTokens.Colors.Yellow.Yellow100.value,

  Green10: styleTokens.Colors.Green.Green10.value,
  Green20: styleTokens.Colors.Green.Green20.value,
  Green30: styleTokens.Colors.Green.Green30.value,
  Green40: styleTokens.Colors.Green.Green40.value,
  Green50: styleTokens.Colors.Green.Green50.value,
  Green60: styleTokens.Colors.Green.Green60.value,
  Green70: styleTokens.Colors.Green.Green70.value,
  Green80: styleTokens.Colors.Green.Green80.value,
  Green90: styleTokens.Colors.Green.Green90.value,
  Green100: styleTokens.Colors.Green.Green100.value,

  Orange10: styleTokens.Colors.Orange.Orange10.value,
  Orange20: styleTokens.Colors.Orange.Orange20.value,
  Orange30: styleTokens.Colors.Orange.Orange30.value,
  Orange40: styleTokens.Colors.Orange.Orange40.value,
  Orange50: styleTokens.Colors.Orange.Orange50.value,
  Orange60: styleTokens.Colors.Orange.Orange60.value,
  Orange70: styleTokens.Colors.Orange.Orange70.value,
  Orange80: styleTokens.Colors.Orange.Orange80.value,
  Orange90: styleTokens.Colors.Orange.Orange90.value,
  Orange100: styleTokens.Colors.Orange.Orange100.value,

  Purple10: styleTokens.Colors.Purple.Purple10.value,
  Purple20: styleTokens.Colors.Purple.Purple20.value,
  Purple30: styleTokens.Colors.Purple.Purple30.value,
  Purple40: styleTokens.Colors.Purple.Purple40.value,
  Purple50: styleTokens.Colors.Purple.Purple50.value,
  Purple60: styleTokens.Colors.Purple.Purple60.value,
  Purple70: styleTokens.Colors.Purple.Purple70.value,
  Purple80: styleTokens.Colors.Purple.Purple80.value,
  Purple90: styleTokens.Colors.Purple.Purple90.value,
  Purple100: styleTokens.Colors.Purple.Purple100.value,

  BlueGreen10: styleTokens.Colors.BlueGreen.BlueGreen10.value,
  BlueGreen20: styleTokens.Colors.BlueGreen.BlueGreen20.value,
  BlueGreen30: styleTokens.Colors.BlueGreen.BlueGreen30.value,
  BlueGreen40: styleTokens.Colors.BlueGreen.BlueGreen40.value,
  BlueGreen50: styleTokens.Colors.BlueGreen.BlueGreen50.value,
  BlueGreen60: styleTokens.Colors.BlueGreen.BlueGreen60.value,
  BlueGreen70: styleTokens.Colors.BlueGreen.BlueGreen70.value,
  BlueGreen80: styleTokens.Colors.BlueGreen.BlueGreen80.value,
  BlueGreen90: styleTokens.Colors.BlueGreen.BlueGreen90.value,
  BlueGreen100: styleTokens.Colors.BlueGreen.BlueGreen100.value,

  Pink10: styleTokens.Colors.Pink.Pink10.value,
  Pink20: styleTokens.Colors.Pink.Pink20.value,
  Pink30: styleTokens.Colors.Pink.Pink30.value,
  Pink40: styleTokens.Colors.Pink.Pink40.value,
  Pink50: styleTokens.Colors.Pink.Pink50.value,
  Pink60: styleTokens.Colors.Pink.Pink60.value,
  Pink70: styleTokens.Colors.Pink.Pink70.value,
  Pink80: styleTokens.Colors.Pink.Pink80.value,
  Pink90: styleTokens.Colors.Pink.Pink90.value,
  Pink100: styleTokens.Colors.Pink.Pink100.value,
} as const;

export const Tokens = {
  UiBackground01: ColorPalette.White,
  UiBackground01Dark: ColorPalette.Gray100,
  UiBackground02: ColorPalette.Gray10,
  UiBackground02Dark: ColorPalette.Gray90,
  UiBackground02Blue: ColorPalette.Blue10,
  UiBackgroundBlue: ColorPalette.Blue10,
  UiBackgroundBlueDark: ColorPalette.Blue80,
  UiBackgroundGray: ColorPalette.Gray10,
  UiBackgroundGrayDark: ColorPalette.Gray70,
  UiBackgroundSuccess: ColorPalette.Green10,
  UiBackgroundSuccessDark: ColorPalette.Green90,
  UiBackgroundError: ColorPalette.Red10,
  UiBackgroundErrorDark: ColorPalette.Red80,
  UiBackgroundWarning: ColorPalette.Yellow10,
  UiBackgroundWarningDark: ColorPalette.Yellow80,
  UiBackgroundTooltip: ColorPalette.Gray100,
  UiBackgroundTooltipDark: ColorPalette.Gray10,
  BackgroundOverlayGray: ColorPalette.Gray100,
  BackgroundOverlayBlack: ColorPalette.Black,

  UiBorder01: ColorPalette.Gray30,
  UiBorder01Dark: ColorPalette.Gray80,
  UiBorder02: ColorPalette.Gray40,
  UiBorder02Dark: ColorPalette.Gray70,

  Text01: ColorPalette.Gray100,
  Text01Dark: ColorPalette.White,
  Text02: ColorPalette.Gray60,
  Text02Dark: ColorPalette.Gray40,
  Text03: ColorPalette.Gray70,
  Text03Dark: ColorPalette.Gray50,
  TextPlaceholder: ColorPalette.Gray40,
  TextPlaceholderDark: ColorPalette.Gray70,
  TextOnColor: ColorPalette.White,
  TextBlack: ColorPalette.Black,

  Icon01: ColorPalette.Gray70,
  Icon01Dark: ColorPalette.Gray40,
  Icon02: ColorPalette.Gray50,
  Icon02Dark: ColorPalette.Gray70,
  Icon03: ColorPalette.Gray40,
  Icon03Dark: ColorPalette.Gray80,
  IconOnColor: ColorPalette.White,

  Link01: ColorPalette.Blue50,
  Link01Dark: ColorPalette.Blue50,
  Link02: ColorPalette.Gray70,
  Link02Dark: ColorPalette.Gray50,

  FieldInput: ColorPalette.White,
  FieldInputDark: ColorPalette.Gray100,
  FieldSearch: ColorPalette.White,
  FieldSearchDark: ColorPalette.Gray80,

  Interactive01: ColorPalette.Blue50,
  Interactive01Dark: ColorPalette.Blue50,
  Interactive02: ColorPalette.Gray70,
  Interactive02Dark: ColorPalette.Gray40,
  Interactive03: ColorPalette.Blue50,
  Interactive03Dark: ColorPalette.Gray70,

  Hover01: ColorPalette.Blue70,
  Hover01Dark: ColorPalette.Blue70,
  Hover02: ColorPalette.Gray20,
  Hover02Dark: ColorPalette.Gray80,
  Hover02Background: ColorPalette.Gray20,
  Hover02BackgroundDark: ColorPalette.Gray90,
  HoverUi: ColorPalette.Gray20,
  HoverUiDark: ColorPalette.Gray80,
  HoverUiBorder: ColorPalette.Gray70,
  HoverUiBorderDark: ColorPalette.Gray40,
  HoverSelectedUi: ColorPalette.Gray40,
  HoverSelectedUiDark: ColorPalette.Gray70,
  HoverDanger: ColorPalette.Red70,
  HoverDangerDark: ColorPalette.Red20,
  HoverInput: ColorPalette.Gray70,
  HoverInputDark: ColorPalette.Gray50,
  HoverLink01: ColorPalette.Blue70,
  HoverLink01Dark: ColorPalette.Blue30,
  HoverLink02: ColorPalette.Gray90,
  HoverLink02Dark: ColorPalette.Gray30,

  Active01: ColorPalette.Blue100,
  Active01Dark: ColorPalette.Blue100,
  Active02: ColorPalette.Gray40,
  Active02Dark: ColorPalette.Gray60,
  Active02Background: ColorPalette.Gray30,
  Active02BackgroundDark: ColorPalette.Gray80,
  ActiveUi: ColorPalette.Blue20,
  ActiveUiDark: ColorPalette.Gray70,
  ActiveSelectedUi: ColorPalette.Blue50,
  ActiveSelectedUiDark: ColorPalette.Blue50,
  ActiveDanger: ColorPalette.Red70,
  ActiveDangerDark: ColorPalette.Red20,
  ActiveInput: ColorPalette.Blue70,
  ActiveInputDark: ColorPalette.Gray40,
  ActiveLink01: ColorPalette.Blue100,
  ActiveLink01Dark: ColorPalette.Blue20,
  ActiveLink02: ColorPalette.Gray100,
  ActiveLink02Dark: ColorPalette.Gray20,

  SelectedUi: ColorPalette.Blue20,
  SelectedUiDark: ColorPalette.Blue70,
  SelectedUiGray: ColorPalette.Gray30,
  SelectedUiGrayDark: ColorPalette.Gray70,
  SelectedUiOnColor: ColorPalette.White,

  Disabled01: ColorPalette.Gray30,
  Disabled01Dark: ColorPalette.Gray80,
  Disabled02: ColorPalette.Gray10,
  Disabled02Dark: ColorPalette.Gray60,
  Disabled03: ColorPalette.Gray90,
  Disabled04: ColorPalette.Blue100,
  DisabledLink01: ColorPalette.Gray30,
  DisabledLink01Dark: ColorPalette.Blue80,
  DisabledLink02: ColorPalette.Gray30,
  DisabledLink02Dark: ColorPalette.Gray80,
  DisabledOn: ColorPalette.Blue20,
  DisabledOnDark: ColorPalette.Blue30,

  Focus: ColorPalette.Blue50,
  FocusDark: ColorPalette.Blue50,

  SupportWarning: ColorPalette.Yellow60,
  SupportWarningDark: ColorPalette.Yellow40,
  SupportError: ColorPalette.Red50,
  SupportErrorDark: ColorPalette.Red40,
  SupportDanger: ColorPalette.Red50,
  SupportDangerDark: ColorPalette.Red40,
  SupportSuccess: ColorPalette.Green50,
  SupportSuccessDark: ColorPalette.Green40,
} as const;

export const UserColorTokens = {
  Red: styleTokens.User.Red.value,
  Pink: styleTokens.User.Pink.value,
  Purple: styleTokens.User.Purple.value,
  Turquoise: styleTokens.User.Turquoise.value,
  RoyalBlue: styleTokens.User.RoyalBlue.value,
  Blue: styleTokens.User.Blue.value,
  Aquamarine: styleTokens.User.Aquamarine.value,
  YellowGreen: styleTokens.User.YellowGreen.value,
  Yellow: styleTokens.User.Yellow.value,
  Orange: styleTokens.User.Orange.value,
} as const;

export type ColorType = ValueOf<typeof ColorPalette>;
export type ColorNameType = keyof typeof ColorPalette;
export type ColorTokenType = keyof typeof Tokens;
export type UserColorType = keyof typeof UserColorTokens;
