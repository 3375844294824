export const Indexes = {
  OnPage: 5,
  Badge: 10,
  GlobalHeader: 50,
  Tooltip: 60,
  TalkTools: 90,
  Overlay: 90,
  Modal: 100,
  OnModal: 200,
  TooltipOnModal: 300,
  Preloader: 1000,
  Snackbar: 1100,
  Popover: 1200,
} as const;
