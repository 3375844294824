import React, { ReactNode, createContext, useCallback, useMemo, useState, useContext } from 'react';

import { Container } from '@/components/layouts/Snackbar/Container';

type Props = {
  children: ReactNode;
};

type Theme = 'success' | 'danger' | 'info' | 'warning';

export type Snackbar = {
  id: number;
} & SnackbarProps;

export type SnackbarWrapperProps = {
  type: 'success' | 'error';
  methodType: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
};

export type SnackbarProps = {
  content: string;
  theme: Theme;
  buttonText?: string;
  onClickButton?: () => void;
  autoHide?: boolean;
};

const SnackbarContext = createContext<Context | null>(null);

export const SnackbarProvider = ({ children }: Props) => {
  const [snackbars, setSnackbars] = useState<Snackbar[]>([]);

  const addSnackbar = useCallback(
    ({ content, theme, buttonText, onClickButton, autoHide }: SnackbarProps) =>
      content !== '' &&
      setSnackbars(prev => [...prev, { id: Date.now(), content, theme, buttonText, onClickButton, autoHide }]),
    [],
  );

  const removeSnackbar = useCallback((id: number) => {
    setSnackbars(prev => prev.filter(snackbar => snackbar.id !== id));
  }, []);

  const callSnackbar = useCallback(
    ({ type, methodType }: SnackbarWrapperProps) => {
      let keyword;
      switch (methodType) {
        case 'GET':
          keyword = `取得を`;
          break;
        case 'POST':
          keyword = `登録を`;
          break;
        case 'PATCH':
          keyword = `編集を`;
          break;
        case 'DELETE':
          keyword = `削除を`;
          break;
        default:
          keyword = ``;
          break;
      }
      const t = type === 'success' ? 'success' : 'danger';
      const message = type === 'success' ? `${keyword}完了しました。` : 'エラーが発生しました。';
      return addSnackbar({
        content: message,
        theme: t,
        autoHide: true,
      });
    },
    [addSnackbar],
  );

  const contextValue = useMemo(
    () => ({
      addSnackbar,
      removeSnackbar,
      callSnackbar,
    }),
    [addSnackbar, removeSnackbar, callSnackbar],
  );
  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
      <Container snackbars={snackbars} />
    </SnackbarContext.Provider>
  );
};

type Context = {
  callSnackbar: ({ type, methodType }: SnackbarWrapperProps) => void;
  addSnackbar: ({ content, theme, buttonText, onClickButton, autoHide }: SnackbarProps) => void;
  removeSnackbar: (id: number) => void;
};

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (context == null) {
    throw new Error('useSnackbarContext must be used within SnackbarProvider');
  }
  return context;
};
