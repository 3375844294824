import { useEffect, useState } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Provider as JotaiProvider } from 'jotai';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { SessionProvider } from 'next-auth/react';
import { DefaultSeo } from 'next-seo';

import { EnvLabel } from '@/components/layouts/EnvLabel';
import { SnackbarProvider } from '@/components/layouts/Snackbar';
import { commonUrl, datadogConfig } from '@/config/Variant';
import { ResetCss } from '@/styles';
import { NextPageWithLayout } from '@/types/next';

// 日時処理のdayjsのpluginをグローバルで読み込んでおく
import 'dayjs/locale/ja';
dayjs.locale('ja');
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

if (datadogConfig.env !== 'local') {
  // https://docs.datadoghq.com/ja/real_user_monitoring/browser/
  datadogRum.init({
    applicationId: datadogConfig.applicationId,
    clientToken: datadogConfig.clientToken,
    service: datadogConfig.service,
    env: datadogConfig.env,
    sessionReplaySampleRate: 0,
    version: process.env.GIT_COMMIT_HASH,
    silentMultipleInit: true,
  });

  // https://docs.datadoghq.com/ja/logs/log_collection/javascript/
  datadogLogs.init({
    clientToken: datadogConfig.clientToken,
    service: datadogConfig.service,
    env: datadogConfig.env,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['info', 'warn', 'error'],
    forwardReports: 'all',
    version: process.env.GIT_COMMIT_HASH,
    silentMultipleInit: true,
  });
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      gcTime: Infinity,
    },
  },
});

if (process.env.NODE_ENV === 'development') {
  require('@/utils/wdyr');
}

if (process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  require('@/mocks');
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const router = useRouter();
  const getLayout = Component.getLayout || (page => page);
  const [isRouteReady, setIsRouteReady] = useState(false);

  useEffect(() => setIsRouteReady(router.isReady), [router.isReady]);

  return (
    <>
      <DefaultSeo
        titleTemplate="%s | revii"
        defaultTitle="revii"
        description="つながりを強化する1on1改善サポートAI"
        additionalMetaTags={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            name: 'theme-color',
            content: '#000000',
          },
        ]}
        openGraph={{
          url: 'https://app.revii.jp/',
          type: 'website',
          locale: 'ja_JP',
          site_name: 'revii',
          description: 'つながりを強化する1on1改善サポートAI',
          images: [{ url: `${commonUrl}/ogp.png` }],
        }}
      />
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/apple-icon-180.png" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      <SessionProvider session={session}>
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <JotaiProvider>
              {isRouteReady && getLayout(<Component {...pageProps} />)}
              <ReactQueryDevtools initialIsOpen={false} />
            </JotaiProvider>
          </QueryClientProvider>
          <ResetCss />
        </SnackbarProvider>
      </SessionProvider>
      <EnvLabel />
    </>
  );
}

export default MyApp;
