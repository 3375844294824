// see https://zenkigen.atlassian.net/wiki/spaces/ZEN/pages/1776277/User+Agent
import { isAndroid, isMobile as rddIsMobile, isMobileOnly as rddIsMobileOnly } from 'react-device-detect';

export { isAndroid, isIOS, isEdge, isChrome, isFirefox, isMobileSafari } from 'react-device-detect';

// これより狭いスクリーン幅のタブレットは、
// 十分な幅のないタブレットとみなし、Mobile扱いする。
// 7.9inches のデバイス(iPad Mini)基準
const MINIMUM_TABLET_SIZE_PX = 768;

export const isBrowser = typeof window !== 'undefined';

export const isSmallerThanTabletSize = (() =>
  isBrowser && (window.screen.width < MINIMUM_TABLET_SIZE_PX || window.screen.height < MINIMUM_TABLET_SIZE_PX))();

// Androidタブレット端末は、一律、スクリーンサイズでも判定。
// UAでのMobile判定は、想定と異なることがあるので。
export const isMobileOnly = (isAndroid && isSmallerThanTabletSize) || rddIsMobileOnly;

export const isMobile = isAndroid || rddIsMobile;

// Mobile Safari has some audio problems on long running mode.
export const needLongRunningMode = isMobileOnly && isAndroid;
