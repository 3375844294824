import React from 'react';

import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { ZIndexes, space } from '@/styles';
import { isBrowser } from '@/utils/helpers/DeviceHelper';

import { Snackbar } from '..';
import { Item } from '../Item';


type Props = {
  snackbars: Snackbar[];
};

export const Container = ({ snackbars }: Props) =>
  isBrowser ? (
    createPortal(
      <StyledBase>
        {snackbars.map(({ id, content, theme, buttonText, onClickButton, autoHide = false }) => (
          <Item
            key={id}
            id={id}
            theme={theme}
            buttonText={buttonText}
            onClickButton={onClickButton}
            autoHide={autoHide}
          >
            {content}
          </Item>
        ))}
      </StyledBase>,
      document.body,
    )
  ) : (
    <StyledBase />
  );

const StyledBase = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: ${ZIndexes.Snackbar};
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin-bottom: ${space(4)};
  pointer-events: none;
`;
