/**
 * 環境変数から各種 URL を読み込む
 * 環境変数は CloudBuild にて登録
 */

// TODO: project_id に変更する、 cloud build の設定タイミングにあわせる
// export const DEVELOP = 'workplace-development-307400';
// export const STAGING = 'workplace-staging';
// export const PRODUCTION = 'workplace-production-314900';

export const LOCAL = 'local';
export const DEVELOP = 'development';
export const STAGING = 'staging';
export const PRODUCTION = 'production';

type EnvType = typeof LOCAL | typeof DEVELOP | typeof STAGING | typeof PRODUCTION;

export const env: EnvType = (process.env.REACT_APP_ENV as EnvType | undefined) ?? LOCAL;

export const commonUrl = {
  [LOCAL]: 'http://localhost:3000',
  [DEVELOP]: 'https://app.development.revii.jp',
  [STAGING]: 'https://app.staging.revii.jp',
  [PRODUCTION]: 'https://app.revii.jp',
}[env];

export const commonApi = {
  [LOCAL]: 'http://localhost',
  [DEVELOP]: 'https://api.development.revii.jp',
  [STAGING]: 'https://api.staging.revii.jp',
  [PRODUCTION]: 'https://api.revii.jp',
}[env];

export const auth0Config = {
  clientId: {
    [LOCAL]: 'aR44KhrbYHexGoLc6tBSxye3xLSQifyY',
    [DEVELOP]: 'aR44KhrbYHexGoLc6tBSxye3xLSQifyY',
    [STAGING]: 'wune2eu6SvVhGXNWe9dmZmygfN9gbMkG',
    [PRODUCTION]: '7v8CPlvebsf6ekTQgKYjT6i5ERqZADzn',
  }[env],
  domain: {
    [LOCAL]: 'https://rebii-development-2.jp.auth0.com',
    [DEVELOP]: 'https://rebii-development-2.jp.auth0.com',
    [STAGING]: 'https://auth.staging.revii.jp',
    [PRODUCTION]: 'https://auth.revii.jp',
  }[env],
};

export const streamingUrl = {
  [LOCAL]: 'http://localhost:4000',
  [DEVELOP]: 'https://whiteboard.development.revii.jp',
  [STAGING]: 'https://whiteboard.staging.revii.jp',
  [PRODUCTION]: 'https://whiteboard.revii.jp',
}[env];

export const memoStreamingUrl = {
  [LOCAL]: 'ws://localhost:4001',
  [DEVELOP]: 'wss://sharenote-streaming.development.revii.jp',
  [STAGING]: 'wss://sharenote-streaming.staging.revii.jp',
  [PRODUCTION]: 'wss://sharenote-streaming.revii.jp',
}[env];

export const datadogConfig = {
  applicationId: '27fc03b9-1dc4-43ff-a295-c9abd26ef1f1',
  clientToken: 'pub45191c3ebb241e896b57473401056d55',
  service: 'revii-frontend',
  env,
};

// Slack App の Manage Distribution で表示されているURL
// アプリの権限が変わった場合は変更が必要
export const SlackSharableUrl = {
  [LOCAL]:
    'https://slack.com/oauth/v2/authorize?client_id=636038291698.2817953049286&scope=users:read.email,users:read,chat:write&user_scope=',
  [DEVELOP]:
    'https://slack.com/oauth/v2/authorize?client_id=636038291698.3299491008626&scope=users:read.email,users:read,chat:write&user_scope=',
  [STAGING]:
    'https://slack.com/oauth/v2/authorize?client_id=636038291698.3326784676372&scope=users:read.email,users:read,chat:write&user_scope=',
  [PRODUCTION]:
    'https://slack.com/oauth/v2/authorize?client_id=636038291698.3324461941379&scope=users:read.email,users:read,chat:write&user_scope=',
}[env];
