import React, { CSSProperties, forwardRef, ReactNode, useCallback, useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import { IconName } from '@Icon';

import { Icon } from '@/components/shared/Icon';
import { BorderRadius, ColorTokenType, ColorTokens, IconToken, TypographyTokens, space, square } from '@/styles';

const defaultDelayTime = 2000;

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonStyling = 'fill' | 'outline' | 'text' | 'icon' | 'icon-outline';
export type BorderStyling = 'normal' | 'circle';
export type ButtonColor = 'default' | 'danger' | 'gray' | 'white';

type Props = {
  children?: ReactNode;
  href?: string;
  hrefTarget?: string;
  icon?: IconName;
  iconSize?: IconToken;
  iconPosition?: 'left' | 'right';
  iconColor?: ColorTokenType;
  badgeNumber?: number;
  widthStyle?: 'fit' | 'fixed' | 'fill';
  width?: CSSProperties['width'];
  isDisabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  styling?: ButtonStyling;
  borderStyling?: BorderStyling;
  delayTime?: number;
  color?: ButtonColor;
  padding?: string;
  opacity?: string;
  size?: ButtonSize;
  className?: string;
  nextLink?: boolean;
};

export const Button = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      href,
      hrefTarget = '_blank',
      icon,
      iconSize,
      iconPosition = 'left',
      badgeNumber,
      widthStyle,
      width,
      onClick,
      isDisabled = false,
      styling = 'fill',
      borderStyling = 'normal',
      delayTime = defaultDelayTime,
      color = 'default',
      iconColor,
      padding,
      opacity,
      size = 'large',
      className,
      nextLink = false,
    }: Props,
    ref,
  ) => {
    const [isDisabledFromTimer, setIsDisabledFromTimer] = useState(false);

    useEffect(() => {
      if (!isDisabledFromTimer) {
        return undefined;
      }
      const buttonTimerId = setTimeout(() => {
        setIsDisabledFromTimer(false);
      }, delayTime);
      return () => {
        clearTimeout(buttonTimerId);
        setIsDisabledFromTimer(false);
      };
    }, [delayTime, isDisabledFromTimer]);

    const handleButtonClick = useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setIsDisabledFromTimer(true);
        onClick?.(e);
      },
      [onClick],
    );

    return (
      <ButtonWrapper
        href={href}
        hrefTarget={hrefTarget}
        onClick={handleButtonClick}
        styling={styling}
        borderStyling={borderStyling}
        isDisabled={isDisabled || isDisabledFromTimer}
        widthStyle={widthStyle}
        width={width}
        delayTime={delayTime}
        color={color}
        padding={padding}
        opacity={opacity}
        size={size}
        className={className}
        nextLink={nextLink}
        innerRef={ref}
      >
        {styling !== 'icon' && styling !== 'icon-outline' && iconPosition === 'left' && icon != null ? (
          <Icon
            name={icon}
            className="button-icon"
            size={iconSize == null ? (size === 'large' ? 'Large' : 'Small') : iconSize}
            badgeNumber={badgeNumber}
            badgeNoNumber={size === 'small'}
            isDisabled={isDisabled}
            color={iconColor}
          />
        ) : null}
        {styling === 'icon' || styling === 'icon-outline' ? (
          <Icon
            name={icon != null ? icon : 'plus'}
            className="button-icon"
            size={iconSize == null ? (size === 'small' ? 'Small' : 'Large') : iconSize}
            badgeNumber={badgeNumber}
            badgeNoNumber={size === 'small'}
            isDisabled={isDisabled}
          />
        ) : (
          children != null && children
        )}
        {styling !== 'icon' && styling !== 'icon-outline' && iconPosition === 'right' && icon != null ? (
          <Icon
            name={icon}
            className="button-icon"
            size={iconSize == null ? (size === 'large' ? 'Large' : 'Small') : iconSize}
            badgeNumber={badgeNumber}
            badgeNoNumber={size === 'small'}
            isDisabled={isDisabled}
          />
        ) : null}
      </ButtonWrapper>
    );
  },
);
Button.displayName = 'Button';

type ButtonWrapperProps = Pick<
  Props,
  | 'href'
  | 'hrefTarget'
  | 'onClick'
  | 'width'
  | 'widthStyle'
  | 'delayTime'
  | 'padding'
  | 'opacity'
  | 'className'
  | 'nextLink'
> &
  Required<Pick<Props, 'styling' | 'borderStyling' | 'isDisabled' | 'color' | 'size'>> & {
    children: ReactNode[];
    innerRef: React.Ref<HTMLAnchorElement>;
  };

const ButtonWrapper = ({
  onClick,
  href,
  hrefTarget,
  children,
  styling,
  borderStyling,
  isDisabled,
  widthStyle,
  width,
  color,
  padding,
  opacity,
  size,
  className,
  nextLink,
  innerRef,
}: ButtonWrapperProps) => {
  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onClick?.(e);
      if (href != null) {
        window.open(href, hrefTarget);
      }
    },
    [onClick, href, hrefTarget],
  );

  if (nextLink) {
    return (
      <StyledLink
        color={color}
        styling={styling}
        borderStyling={borderStyling}
        width={width}
        widthStyle={widthStyle}
        padding={padding}
        opacity={opacity}
        href={href}
        id={children.toString()}
        size={size}
        className={className}
        ref={innerRef}
        onClick={onClick}
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      color={color}
      onClick={handleOnClick}
      styling={styling}
      borderStyling={borderStyling}
      disabled={isDisabled}
      width={width}
      widthStyle={widthStyle}
      padding={padding}
      opacity={opacity}
      id={children.toString()}
      size={size}
      className={className}
    >
      {children}
    </StyledButton>
  );
};

const commonWrapperStyle = css<
  Pick<Props, 'width' | 'widthStyle' | 'padding' | 'opacity' | 'styling'> &
    Required<Pick<Props, 'styling' | 'borderStyling' | 'color' | 'size'>>
>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${props => (props.size === 'small' ? '24px' : props.size === 'large' ? '40px' : '32px')};
  ${props =>
    props.opacity != null
      ? css`
          opacity: ${props.opacity};
        `
      : null}
  ${props =>
    props.widthStyle === 'fit'
      ? props.size === 'small'
        ? css`
            width: fit-content;
            padding: 0 ${space(2)};
          `
        : props.size === 'medium'
        ? css`
            width: fit-content;
            padding: 0 ${space(3)};
          `
        : css`
            width: fit-content;
            padding: 0 ${space(4)};
          `
      : props.widthStyle === 'fixed'
      ? css`
          width: ${props.width != null ? props.width : '180px'};
        `
      : css`
          width: 100%;
        `}
  ${props =>
    props.styling !== 'icon' && props.styling !== 'icon-outline'
      ? props.width != null && props.widthStyle !== 'fit'
        ? css`
            width: ${props.width};
          `
        : null
      : css`
          ${square(props.size === 'small' ? '24px' : '32px')};
        `}
  ${props => (props.size === 'large' ? TypographyTokens.Label2 : TypographyTokens.Label4)}
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  ${props =>
    props.padding != null
      ? css`
          padding: ${props.padding};
        `
      : null}
  ${props =>
    props.borderStyling === 'circle'
      ? css`
          border-radius: ${BorderRadius.Circle};
        `
      : css`
          border-radius: ${BorderRadius.Default};
        `}
  outline: none;
  transition: all 0.15s ease;
  :disabled {
    pointer-events: none;
    cursor: not-allowed;
  }
  ${({ styling, color }) => {
    switch (styling) {
      case 'text':
      case 'icon':
        return css`
          color: ${color === 'danger' ? ColorTokens.SupportDanger : ColorTokens.Interactive02};
          background-color: transparent;
          border: none;
          & svg {
            fill: ${color === 'danger' ? ColorTokens.SupportDanger : ColorTokens.Interactive02};
          }
          &:hover {
            background-color: ${color === 'danger' ? ColorTokens.HoverDangerDark : ColorTokens.Hover02};
          }
          &:disabled {
            color: ${ColorTokens.Disabled01};
            background-color: transparent;
          }
          &:disabled svg {
            fill: ${ColorTokens.Disabled01};
          }
          &:active {
            background-color: ${color === 'danger' ? ColorTokens.ActiveDangerDark : ColorTokens.Active02};
          }
        `;
      case 'outline':
      case 'icon-outline':
        return css`
          color: ${color === 'danger'
            ? ColorTokens.SupportDanger
            : color === 'gray'
            ? ColorTokens.Interactive02Dark
            : ColorTokens.Interactive02};
          background-color: ${color === 'gray' ? ColorTokens.UiBackground01Dark : 'transparent'};
          border: 1px solid
            ${color === 'danger'
              ? ColorTokens.SupportDanger
              : color === 'gray'
              ? ColorTokens.UiBorder02Dark
              : ColorTokens.UiBorder02};
          & svg {
            fill: ${color === 'danger'
              ? ColorTokens.SupportDanger
              : color === 'white'
              ? ColorTokens.IconOnColor
              : ColorTokens.Icon01};
          }
          &:hover {
            background-color: ${color === 'danger'
              ? ColorTokens.HoverDangerDark
              : color === 'gray'
              ? ColorTokens.HoverLink02
              : color === 'white'
              ? ColorTokens.Hover02Dark
              : ColorTokens.Hover02};
          }
          &:disabled {
            color: ${color === 'gray' ? ColorTokens.Disabled02Dark : ColorTokens.Disabled01};
            background-color: ${color === 'gray' ? ColorTokens.UiBackground01Dark : 'transparent'};
            border: 1px solid ${color === 'gray' ? ColorTokens.UiBorder02Dark : ColorTokens.Disabled01};
          }
          &:disabled svg {
            fill: ${ColorTokens.Disabled01};
          }
          &:active {
            background-color: ${color === 'danger' ? ColorTokens.ActiveDangerDark : ColorTokens.Active02};
          }
        `;
      default:
        return css`
          color: ${ColorTokens.TextOnColor};
          background-color: ${color === 'danger'
            ? ColorTokens.SupportDanger
            : color === 'gray'
            ? ColorTokens.Interactive03Dark
            : ColorTokens.Interactive01};
          & svg {
            fill: ${ColorTokens.IconOnColor};
          }
          &:hover {
            background-color: ${color === 'danger'
              ? ColorTokens.HoverDanger
              : color === 'gray'
              ? ColorTokens.Hover02Dark
              : ColorTokens.Hover01};
          }
          &:disabled {
            background-color: ${ColorTokens.Disabled01};
          }
          &:active {
            background-color: ${color === 'danger'
              ? ColorTokens.ActiveDanger
              : color === 'gray'
              ? ColorTokens.Active02Dark
              : ColorTokens.Active01};
          }
        `;
    }
  }}

  .button-icon {
    display: flex;
    margin: 0 ${space(1)};
  }
`;

const StyledButton = styled.button<
  Pick<Props, 'width' | 'widthStyle' | 'padding' | 'opacity'> &
    Required<Pick<Props, 'styling' | 'borderStyling' | 'color' | 'size'>>
>`
  ${commonWrapperStyle}
`;

const StyledLink = styled.a<
  Pick<Props, 'width' | 'widthStyle' | 'padding' | 'opacity'> &
    Required<Pick<Props, 'styling' | 'borderStyling' | 'color' | 'size'>>
>`
  ${commonWrapperStyle}
`;
