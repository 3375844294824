import styled from 'styled-components';

import { env, LOCAL, DEVELOP, STAGING, PRODUCTION } from '@/config/Variant';
import { space, ColorTokens, BorderRadius, ZIndexes } from '@/styles';

const envDisplayColor = {
  [LOCAL]: 'none',
  [DEVELOP]: 'blue',
  [STAGING]: 'green',
  [PRODUCTION]: 'none',
}[env];

export const EnvLabel = () => {
  return envDisplayColor !== 'none' ? (
    <StyledWrapper color={envDisplayColor}>
      <div>Environment:</div>
      <div>{env}</div>
      <div>Version:</div>
      <div>{process.env.GIT_COMMIT_HASH}</div>
    </StyledWrapper>
  ) : null;
};

const StyledWrapper = styled.div<{ color: string }>`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: ${ZIndexes.Popover};
  padding: ${space(2)};
  color: ${ColorTokens.Text01Dark};
  background-color: ${props => props.color};
  border-radius: ${BorderRadius.Default} 0 0 0;
  animation: fadeout 2s ease-out 4s 1 forwards;
  pointer-events: none;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: ${space(2)};

  @keyframes fadeout {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;
